export default class FaqCategoryModel {
  constructor(){
    this.id = null;
    this.name = '';
    this.deletable = true;
  }
  setData(obj){
    const { id, name, deletable } = obj;
    this.id = id;
    this.name = name || '';
    this.deletable = deletable || true;
  }
  getRegisterData(){
    let obj = {
      name : this.name,
      deletable : this.deletable,
    }
    return obj;
  }
  getData(){
    let obj = {
      id : this.id,
      name : this.name,
      deletable : this.deletable,
    }
    return obj;
  }
}