
import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class FaqTopTenViewModel {
  constructor() {
    this.boardData = {
      drawDataList: [
        {
          title:'순서',
          width:'58px',
          icon:{
            iconName:'drag',
            size:14,
            style:{margin:'12px'}
          },
        },
        {
          title:'No.',
          width:'50px',
          value:'rowIndex',
        },
        {
          title:'카테고리',
          width:'120px',
          value:'categoryName',
          isAlignLeft: true,
          emptyValueText:'⚠️'
        },
        {
          title:'FAQ 제목',
          width:'',
          value:'question',
          isAlignLeft: true,
          emptyValueText:'⚠️'
        },
        {
          title:'편집',
          width:'160px',
          value:'isEmpty',
          buttonCondition: [
            {
              condition:false,
              text:'변경',
              size:'small',
              style:'secondary_border',
              disabled:false,
              icon:{
                iconName:'reset',
                size:10,
                iconColor:'#222'
              },
              onClickEvent: (data,rowIndex) => this.onClickEdit(data,rowIndex)
            },
            {
              condition:true,
              text:'추가',
              size:'small',
              style:'secondary',
              disabled:false,
              icon:{
                iconName:'add',
                size:10,
                iconColor:'#fff',
              },
              onClickEvent: (data,rowIndex) => this.onClickEdit(data,rowIndex)
            },
          ],
        },
      ],
      option:{
        isTotal: false,
        isSize: false,
        isDrag: true,
        dragHandle:'.icon_drag',
        onEndDrag:(e) => this.onEndDrag(e)
      }
    };
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.isDisabledResetBtn = true;
    this.isFaqSearchPopup = false;
    this.editIndex = null
  }
  init(){
    this.getData();
  }
  onClickReset(){
    this.isDisabledResetBtn = true;
    this.getData();
  }
  onEndDrag(e){
    if( e.newIndex === e.oldIndex ) return;
    this.isDisabledResetBtn = false;
  }
  onClickEdit(data,rowIndex){
    this.editIndex = rowIndex;
    this.isFaqSearchPopup = true;
  }
  onClickCloseFaqSearchPopup(){
    this.editIndex = null;
    this.isFaqSearchPopup = false;
  }
  onClickCompleteFaqSearchPopup(data){
    if(this.dataList.find(item => item.id === data.id)){
      store.dispatch('commonToast/fetchToastStart', `TOP 10으로 이미 등록된 FAQ입니다`);
      return;
    }
    this.isFaqSearchPopup = false;
    data.isEmpty = false;
    let dataListSync = [];
    dataListSync = dataListSync.concat(this.dataList);
    dataListSync[this.editIndex] = data;
    this.dataList = dataListSync;
    this.editIndex = null;
  }

  onClickCompleteFaqTopTen(){
    this.dataList.forEach((item, index) => {
      let itemId = '';
      if(!item.isEmpty){
        itemId = item.id;
      }
      this.postData(itemId,index+1)
    })
  }
  getData(){
    let resultData = [];
    const path = `${apiPath.FAQ_TOP_LIST}`;
    GET_REQUEST(path).then(
    (success) => {
      resultData = success.data;
      this.setData(resultData)
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
      this.setData(resultData)
    })
  }
  setData(resultData){
    resultData = resultData.map(item => {
      item.isEmpty = false;
      return item
    })
    if(resultData.length < 10){
      const addDataLength = 10 - resultData.length;
      let newIndex = resultData.length +1;
      for(var i = 0; i < addDataLength; i++){
        let newData = {
          id : `new${i}`,
          category : '',
          question : '',
          answer : '',
          isEmpty: true
        }
        resultData.push(newData)
        newIndex += 1;
      }
    }
    this.dataList = resultData
    this.paginationData.totalCount = 10;
    this.paginationData.totalPage = 1;
  }
  postData(id,position){
    const path = `${apiPath.FAQ_TOP_REGISTER.format(id,position)}`;
    POST_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      if(position === 10){
        store.dispatch('commonToast/fetchToastStart', `FAQ TOP 10이 저장되었습니다.`);
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}