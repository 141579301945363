import router from '@/router';
import { GET_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';
import { setDataList } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";

export default class FaqSearchPopupViewModel {
  constructor() {
    this.searchDataList = [
      {
        title:'',
        type: 'SelectboxInput',
        dataList:[],
        isFull:true,
        value1:null,
        value2:'',
      },
    ]
    this.boardData = {
      title:'FAQ 검색결과',
      drawDataList: [
        {
          title:'카테고리',
          width:'120px',
          value:'categoryName',
          isAlignLeft: true
        },
        {
          title:'Q&A',
          width:'',
          value:'question',
          isAlignLeft: true
        },
      ],
      option:{
        isTotal: true,
        isSize: true
      },
      markData:[
        {
          id : this.searchDataList[0].value1,
          value : this.searchDataList[0].value2
        }
      ]
    };
    this.dataList = [];
    this.foldedDataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.selectedDataList = [];
  }
  init(){
    this.getData();
  }
  onSearch(){
    this.getData();
  }
  onClickRow(rowId,rowData){
    if(this.selectedDataList.length === 0){
      this.selectedDataList.push(rowData)
    }else if(this.selectedDataList[0].id != rowData.id){
      this.selectedDataList = [];
      this.selectedDataList.push(rowData)
    }else{
      this.selectedDataList = [];
    }
    // const isFolded = this.foldedDataList.length > 0 && this.foldedDataList.find(item => item === rowId);
    // if(isFolded){
    //   this.foldedDataList = this.foldedDataList.filter(item => item != rowId);
    // }else{
    //   this.foldedDataList.push(rowId);
    // }
  }
  onClickEdit(data){
    router.push({ name:'SERVICE_FAQ_MODIFY', params: { id : data.id } })
  }
  getData(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.FAQ_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData.list;
      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}