import FaqCategoryModel from '@/views/service/faq/model/FaqCategoryModel';
import { GET_REQUEST, POST_REQUEST, PUT_REQUEST, DELETE_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import { setDataList,addDataListAll } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";

export default class FaqCategoryViewModel {
  constructor() {
    this.categoryData = {
      title : 'FAQ 카테고리',
      id : 'FaqCategory',
      index : 0,
      titleList: [
        { title:'순서', style:{width:'47px'} },
        { title:'카테고리 이름', isFill: true },
        { title:'수정', style:{width:'50px'} },
        { title:'삭제', style:{width:'50px'} },
      ],
      isDragable:true,
      isHead: false,
      value:null
    };
    this.dataList = [];

    // 검색용 카테고리 목록
    this.categorySelectDataList = [];

    this.isFaqCategoryRegisterPopup = false;
    this.faqCategoryRegisterPopupData = {};
  }

  init(setCategorySelectData){
    this.getFaqCategory(setCategorySelectData);
  }
  onModifyOrder(orderData){
    if(orderData){
      this.putFaqCategoryPosition(orderData)
    }
  }
  onClickAddCategory(){
    this.isFaqCategoryRegisterPopup = true;
    const newFaqCategoryModel = new FaqCategoryModel();
    this.faqCategoryRegisterPopupData = newFaqCategoryModel.getRegisterData();
  }
  onClickEditCategoryItem(val){
    this.isFaqCategoryRegisterPopup = true;
    this.faqCategoryRegisterPopupData = val;
  }
  onClickFaqCategoryRegisterPopupClose(){
    this.isFaqCategoryRegisterPopup = false;
    this.faqCategoryRegisterPopupData = {};
  }
  onClickFaqCategoryRegisterPopupComplete(val,isModify){
    this.isFaqCategoryRegisterPopup = false;
    this.faqCategoryRegisterPopupData = {};
    if(isModify){
      this.putFaqCategory(val)
    }else{
      this.postFaqCategory(val)
    }
  }
  onClickDeleteCategoryItem(id){
    const requestDelete = () => {
      this.deleteFaqCategory(id);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `<strong>카테고리를 삭제할까요?</strong>해당 카테고리에 지정된 FAQ 게시물이 있는 경우,<br>기타 카테고리로 변경됩니다.`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  onClickCompleteFaqCagegory(){
    const path = `${apiPath.FAQ_CATEGORY}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData.list || [];
      store.dispatch('commonToast/fetchToastStart', `FAQ 카테고리가 저장되었습니다.`);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  getFaqCategory(setCategorySelectData){
    const path = `${apiPath.FAQ_CATEGORY}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData || [];
      this.categorySelectDataList = addDataListAll(setDataList(this.dataList, 'id', 'name'),null,'전체 카테고리');
      if(setCategorySelectData){
        setCategorySelectData(this.categorySelectDataList);
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  postFaqCategory(val){
    const path = `${apiPath.FAQ_CATEGORY_REGISTER}`;
    const data = val;
    POST_REQUEST(path, data).then(
    (success) => {
      // const resultData = success.data;
      // this.dataList = resultData.list || [];
      store.dispatch('commonToast/fetchToastStart', 'FAQ 카테고리가 추가되었습니다');
      this.getFaqCategory();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  putFaqCategory(val){
    const path = `${apiPath.FAQ_CATEGORY_MODIFY.format(val.id)}`;
    const data = val;
    PUT_REQUEST(path,data).then(
    (success) => {
      // const resultData = success.data;
      // this.dataList = resultData.list || [];
      store.dispatch('commonToast/fetchToastStart', 'FAQ 카테고리가 수정되었습니다');
      this.getFaqCategory();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  putFaqCategoryPosition(val){
    const path = `${apiPath.FAQ_CATEGORY_POSITION.format(val.id)}`;
    const data = {
      "oldPosition": val.oldIndex,
      "newPosition": val.newIndex,
      "badRequest": true
    };
    PUT_REQUEST(path,data).then(
    (success) => {
      // const resultData = success.data;
      // this.dataList = resultData.list || [];
      store.dispatch('commonToast/fetchToastStart', 'FAQ 카테고리 순서가 변경되었습니다');
      this.getFaqCategory();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  deleteFaqCategory(id){
    const path = `${apiPath.FAQ_CATEGORY_DELETE.format(id)}`;
    DELETE_REQUEST(path).then(
    (success) => {
      store.dispatch('commonToast/fetchToastStart', `FAQ 카테고리가 삭제되었습니다.`);
      this.getFaqCategory();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}