<template>
  <Popup
    title="FAQ 검색"
    :maxWidth="800"
    closeBtnText="닫기"
    compeleteBtnText="선택완료"
    :isDisabeldCompeleteBtn="viewModel.selectedDataList.length === 0"
    @onClickClose="$emit('onClickClose')"
    @onClickComplete="$emit('onClickComplete',viewModel.selectedDataList[0])">
    <Search
      :searchDataList="viewModel.searchDataList"
      :searchParams.sync="viewModel.searchParams"
      @onSearch="viewModel.onSearch()"/>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :selectedDataList="viewModel.selectedDataList"
      :paginationData="viewModel.paginationData"
      @onClickRow="(rowId,rowData) => viewModel.onClickRow(rowId,rowData)"
      @onSearch="viewModel.onSearch()">
    </Board>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
// viewModel
import FaqSearchPopupViewModel from '@/views/service/faq/viewModel/FaqSearchPopupViewModel'
import FaqCategoryViewModel from '@/views/service/faq/viewModel/FaqCategoryViewModel'

export default {
  name:'FaqSearchPopup',
  components:{
    Popup,
    Search,
    Board,
  },
  beforeMount(){
    this.viewModel.init();
    const setCategorySelectData = (dataList) => {
      this.viewModel.searchDataList[0].dataList = dataList;
    }
    this.categoryViewModel.init(setCategorySelectData);
  },
  data(){
    return{
      viewModel: new FaqSearchPopupViewModel(),
      categoryViewModel: new FaqCategoryViewModel(),
    }
  },
}
</script>
<style scoped>
</style>